<template>
  <leisure-profile-component @update-title="changeTitle" />
</template>

<script>
import LeisureProfileComponent from "@/components/restaurants-leisures/LeisureProfileComponent";

export default {
  name: "LeisuresProfile",
  title: "Perfil del Leisure | Baja California Health Tourism",
  components: { LeisureProfileComponent },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
